import {
  Box,
  Button,
  Space,
  Text,
  Title,
  List,
  ThemeIcon,
  Center,
  Tabs,
  Input,
  Highlight,
} from "@mantine/core";
import Header from "./components/Header";
import {
  IconBrandGithub,
  IconArrowsSplit,
  IconDatabase,
  IconWorld,
  IconRefresh,
} from "@tabler/icons";
import { app, updateApp } from "./signals/app";
import { user, updateUser } from "./signals/user";
import Footer from "./components/Footer";
import { useNavigate } from "react-router-dom";

const stateTextPersist = `
const state = {
  count: 0,
  clicks: 0,
};
export default state;
`;

const replileTextPersist = `
import { createRepile } from "repilejs";
import state from "./state";

const { getValue, next } = createRepile({
  state: state,
  persist: true,
});

export { getValue as counter, next as updateCounter };
`;

const appTextPersist = `
import { counter, updateCounter } from "./repile";

function Counter() {
  return (
    <button
      onClick={() => {
        updateCounter("count", counter().count - 1);
        updateCounter("clicks", counter().clicks + 1);
      }}
    >
      -
    </button>
    <label>
      {counter().count}
    </label>
    <button
      onClick={() => {
        updateCounter("count", counter().count + 1);
        updateCounter("clicks", counter().clicks + 1);
      }}
    >
      +
    </button>
  )
}
`;

const stateText = `
const state = {
  name: "Varun Bardwaj"
};
export default state;
`;

const replileText = `
import { createRepile } from "repilejs";
import state from "./state";

const { getValue, next } = createRepile({
  state: state,
});

export { getValue as user, next as updateUser };
`;

const appText = `
import { user, updateUser } from "./repile";

function User() {
  return (
    <input
      onChange={(e: React.ChangeEvent) =>
        updateUser("name", (e.target as HTMLInputElement).value)
      } 
    />
    <label>
      Username: {user().name}
    </label>
  )
}
`;

export default function Homepage() {
  const routeTo = useNavigate();

  return (
    <>
      <Box
        sx={{
          "@media (min-width: 0px)": {
            paddingLeft: "2rem",
            paddingRight: "2rem",
          },
          "@media (min-width: 400px)": {
            paddingLeft: "3rem",
            paddingRight: "3rem",
          },
          "@media (min-width: 650px)": {
            paddingLeft: "5rem",
            paddingRight: "5rem",
          },
          "@media (min-width: 800px)": {
            paddingLeft: "8rem",
            paddingRight: "8rem",
          },
          "@media (min-width: 1300px)": {
            paddingLeft: "10rem",
            paddingRight: "10rem",
          },
        }}
      >
        <Header />
        <Space h={180} />
        <Title
          sx={() => ({
            "@media (min-width: 0px)": {
              fontSize: "2.3rem",
            },
            "@media (min-width: 400px)": {
              fontSize: "2.4rem",
            },
            "@media (min-width: 650px)": {
              fontSize: "3.5rem",
            },
            "@media (min-width: 800px)": {
              fontSize: "4.5rem",
            },
            "@media (min-width: 1300px)": {
              fontSize: "5.5rem",
            },
            textAlign: "left",
            lineHeight: "100%",
            fontWeight: 900,
          })}
        >
          A centralized{" "}
          <Text
            variant="gradient"
            gradient={{ from: "#5F3DC4", to: "#9C36B5", deg: 45 }}
            inherit
            component="span"
          >
            {" "}
            store{" "}
          </Text>{" "}
          for React apps.{" "}
        </Title>
        <Space h={30} />
        <Text
          sx={(theme) => ({
            color:
              theme.colorScheme === "dark"
                ? theme.colors.dark[1]
                : theme.colors.dark[4],
            "@media (min-width: 0px)": {
              fontSize: "0.8rem",
            },
            "@media (min-width: 400px)": {
              fontSize: "0.9rem",
            },
            "@media (min-width: 650px)": {
              fontSize: "1.1rem",
            },
            "@media (min-width: 800px)": {
              fontSize: "1.1rem",
            },
            "@media (min-width: 1300px)": {
              fontSize: "1.2rem",
              paddingRight: "10rem",
            },
            textAlign: "left",
          })}
        >
          Write and manage hassel free and blazingly fast global store with
          persist behavior. Say good bye to actions, reducers, other helper
          methods and use{" "}
          <Highlight
            span
            highlight={[" getValue ", " next "]}
            highlightStyles={(theme) => ({
              background: "rgba(121, 80, 241, 0.2)",
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.gray[5]
                  : theme.colors.dark[4],
            })}
          >
            {` getValue and next `}
          </Highlight>{" "}
          methods anywhere in the project to get and update the states.
        </Text>
        <Space h={50} />
        <Box
          sx={(theme) => ({
            display: "flex",
            alignItems: "center",
            color:
              theme.colorScheme === "dark"
                ? theme.colors.dark[1]
                : theme.colors.dark[4],
            fontSize: "1.3rem",
            textAlign: "left",
          })}
        >
          <Button
            variant="gradient"
            gradient={{ from: "#5F3DC4", to: "#9C36B5", deg: 45 }}
            sx={() => ({
              marginRight: "1rem",
              "@media (min-width: 400px)": {
                fontSize: "1rem",
                width: "8rem",
                height: "3rem",
              },
              "@media (min-width: 650px)": {
                fontSize: "1rem",
                width: "8rem",
                height: "3.2rem",
              },
              "@media (min-width: 800px)": {
                fontSize: "1rem",
                width: "9rem",
                height: "3.5rem",
              },
              "@media (min-width: 1300px)": {
                fontSize: "1.1rem",
                width: "10rem",
                height: "3.6rem",
              },
            })}
            onClick={() => {
              updateApp("documentation", "getStarted");
              routeTo("/documentation");
            }}
          >
            Get Started
          </Button>
          <Button
            leftIcon={<IconBrandGithub size={25} />}
            variant="gradient"
            gradient={{
              from: app().dark ? "#2B2E33" : "#25262B",
              to: app().dark ? "#2B2E33" : "#25262B",
              deg: 45,
            }}
            sx={() => ({
              "@media (min-width: 400px)": {
                fontSize: "1rem",
                width: "8rem",
                height: "3rem",
              },
              "@media (min-width: 650px)": {
                fontSize: "1rem",
                width: "8rem",
                height: "3.2rem",
              },
              "@media (min-width: 800px)": {
                fontSize: "1rem",
                width: "9rem",
                height: "3.5rem",
              },
              "@media (min-width: 1300px)": {
                fontSize: "1.1rem",
                width: "10rem",
                height: "3.6rem",
              },
            })}
            onClick={() =>
              window.open("https://github.com/varunpbardwaj/repile")
            }
          >
            Github
          </Button>
        </Box>
      </Box>
      <Space h={120} />
      <Box
        sx={(theme) => ({
          paddingTop: "5rem",
          backgroundColor: `${
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.gray[0]
          }`,
          "@media (min-width: 0px)": {
            paddingLeft: "2rem",
            paddingRight: "2rem",
          },
          "@media (min-width: 400px)": {
            paddingLeft: "3rem",
            paddingRight: "3rem",
          },
          "@media (min-width: 650px)": {
            paddingLeft: "5rem",
            paddingRight: "5rem",
          },
          "@media (min-width: 800px)": {
            paddingLeft: "8rem",
            paddingRight: "8rem",
          },
          "@media (min-width: 1300px)": {
            paddingLeft: "10rem",
            paddingRight: "10rem",
          },
        })}
      >
        <Title
          style={{
            fontWeight: "800",
          }}
          className="underline"
        >
          HIGHLIGHTS
        </Title>
        <Space h={50} />
        <List spacing="lg" size="lg" center>
          <List.Item
            icon={
              <ThemeIcon color="violet.5" size={45} radius="xl">
                <IconWorld size={25} />
              </ThemeIcon>
            }
          >
            <Text weight={700}>Global store.</Text>
          </List.Item>
          <List.Item
            icon={
              <ThemeIcon color="violet.5" size={45} radius="xl">
                <IconArrowsSplit size={25} />
              </ThemeIcon>
            }
          >
            <Text weight={700}>Modular state containers.</Text>
          </List.Item>
          <List.Item
            icon={
              <ThemeIcon color="violet.5" size={45} radius="xl">
                <IconRefresh size={25} />
              </ThemeIcon>
            }
          >
            <Text weight={700}>Reactive states.</Text>
          </List.Item>
          <List.Item
            icon={
              <ThemeIcon color="violet.5" size={45} radius="xl">
                <IconDatabase size={25} />
              </ThemeIcon>
            }
          >
            <Text weight={700}>Persist store.</Text>
          </List.Item>
        </List>
        <Space h={100} />
      </Box>
      <Box
        sx={() => ({
          paddingTop: "5rem",
          "@media (min-width: 0px)": {
            paddingLeft: "2rem",
            paddingRight: "2rem",
          },
          "@media (min-width: 400px)": {
            paddingLeft: "3rem",
            paddingRight: "3rem",
          },
          "@media (min-width: 650px)": {
            paddingLeft: "5rem",
            paddingRight: "5rem",
          },
          "@media (min-width: 800px)": {
            paddingLeft: "8rem",
            paddingRight: "8rem",
          },
          "@media (min-width: 1300px)": {
            paddingLeft: "10rem",
            paddingRight: "10rem",
          },
        })}
      >
        <Title
          order={1}
          style={{
            fontWeight: "800",
          }}
          className="underline"
        >
          EXAMPLES
        </Title>
        <Space h={40} />
        <Title
          order={3}
          style={{
            fontWeight: "800",
          }}
        >
          Counter app with persist behavior
        </Title>
        <Space h={30} />
        <Box
          sx={(theme) => ({
            border: `1px solid ${
              theme.colorScheme === "dark"
                ? theme.colors.gray[8]
                : theme.colors.gray[2]
            }`,
            padding: "2rem",
            borderRadius: "10px",
            overflow: "auto",
          })}
        >
          <Tabs color="violet.5" defaultValue="app.tsx">
            <Tabs.List>
              <Tabs.Tab sx={{ fontSize: "1rem" }} value="state.ts">
                state.ts
              </Tabs.Tab>
              <Tabs.Tab sx={{ fontSize: "1rem" }} value="repile.ts">
                repile.ts
              </Tabs.Tab>
              <Tabs.Tab sx={{ fontSize: "1rem" }} value="app.tsx">
                Counter.tsx
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="state.ts" pt="xl">
              <pre>{stateTextPersist}</pre>
            </Tabs.Panel>
            <Tabs.Panel value="repile.ts" pt="xl">
              <pre>{replileTextPersist}</pre>
            </Tabs.Panel>
            <Tabs.Panel value="app.tsx" pt="xl">
              <pre>{appTextPersist}</pre>
            </Tabs.Panel>
          </Tabs>
          <Space h={50} />
          <Box
            sx={(theme) => ({
              border: `2px solid ${
                theme.colors.violet[theme.fn.primaryShade()]
              }`,
              display: "flex",
              width: "150px",
              height: "50px",
              borderRadius: "70px",
            })}
          >
            <Button
              sx={{ width: "50px", height: "45px", borderRadius: "70px" }}
              variant="subtle"
              color="violet"
              onClick={() => {
                updateApp("counter", app().counter - 1);
                updateApp("clicks", app().clicks + 1);
              }}
            >
              -
            </Button>
            <Center sx={{ width: "50px", height: "inherit" }}>
              {app().counter}
            </Center>
            <Button
              sx={{ width: "50px", height: "45px", borderRadius: "70px" }}
              variant="subtle"
              color="violet"
              onClick={() => {
                updateApp("counter", app().counter + 1);
                updateApp("clicks", app().clicks + 1);
              }}
            >
              +
            </Button>
          </Box>
          <Space h={30} />
          <Title order={6}>Number of clicks: {app().clicks}</Title>
        </Box>
        <Space h={50} />
        <Title
          order={3}
          style={{
            fontWeight: "800",
          }}
        >
          User input without persist behavior
        </Title>
        <Space h={20} />
        <Box
          sx={(theme) => ({
            border: `1px solid ${
              theme.colorScheme === "dark"
                ? theme.colors.gray[8]
                : theme.colors.gray[2]
            }`,
            padding: "2rem",
            borderRadius: "10px",
            overflow: "auto",
          })}
        >
          <Tabs color="violet.5" defaultValue="app.tsx">
            <Tabs.List>
              <Tabs.Tab sx={{ fontSize: "1rem" }} value="state.ts">
                state.ts
              </Tabs.Tab>
              <Tabs.Tab sx={{ fontSize: "1rem" }} value="repile.ts">
                repile.ts
              </Tabs.Tab>
              <Tabs.Tab sx={{ fontSize: "1rem" }} value="app.tsx">
                User.tsx
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="state.ts" pt="xl">
              <pre>{stateText}</pre>
            </Tabs.Panel>
            <Tabs.Panel value="repile.ts" pt="xl">
              <pre>{replileText}</pre>
            </Tabs.Panel>
            <Tabs.Panel value="app.tsx" pt="xl">
              <pre>{appText}</pre>
            </Tabs.Panel>
          </Tabs>
          <Space h={50} />
          <Input
            variant="filled"
            radius="xl"
            size="md"
            placeholder="Start typing..."
            onChange={(e: React.ChangeEvent) =>
              updateUser("name", (e.target as HTMLInputElement).value)
            }
            sx={(theme) => ({
              width: "250px",
              ".mantine-Input-input": {
                "&:focus": {
                  borderColor: `${
                    theme.colors.violet[theme.fn.primaryShade()]
                  } !important`,
                },
              },
            })}
          />
          <Space h={30} />
          <Text>
            Username:{"  "}
            <Text sx={{ fontWeight: 700 }} span>
              {user().name}
            </Text>
          </Text>
        </Box>
      </Box>
      <Space h={100} />
      <Footer />
    </>
  );
}

export {
  stateTextPersist,
  replileTextPersist,
  appTextPersist,
  stateText,
  replileText,
  appText,
};

// Repile
// uses{" "}
// <Text
//   component="a"
//   href="https://www.npmjs.com/package/@preact/signals-react"
//   color="#7950F1"
// >
//   @preact/signals-react
// </Text>{" "}
// under the hood.
