import { ReactNode, useState } from "react";
import LOGO from "./assets/images/repile.png";
import {
  AppShell,
  Navbar,
  Header,
  Text,
  MediaQuery,
  Burger,
  useMantineTheme,
  Badge,
  Box,
  Image,
  Space,
  ActionIcon,
  ThemeIcon,
  NavLink,
  Tabs,
  Input,
  Title,
  Button,
  Center,
} from "@mantine/core";
import { app, updateApp } from "./signals/app";
import {
  IconMoon,
  IconSunHigh,
  IconBrandGithub,
  IconRocket,
  IconStack2,
  IconWorld,
  IconComponents,
} from "@tabler/icons";
import Footer from "./components/Footer";
import packageJSON from "../package.json";
import { useNavigate } from "react-router-dom";
import {
  stateTextPersist,
  replileTextPersist,
  appTextPersist,
  stateText,
  replileText,
  appText,
} from "./Homepage";
import { user, updateUser } from "./signals/user";

const Icon = ({ icon }: { icon: ReactNode }) => (
  <ThemeIcon color="violet.5" size={30} radius="xl">
    {icon}
  </ThemeIcon>
);

const getStarted = () => {
  return (
    <>
      <Title
        order={1}
        style={{
          fontWeight: "800",
        }}
        className="underline"
      >
        Get Started
      </Title>
      <Space h={40} />
      <Title
        order={3}
        style={{
          fontWeight: "800",
        }}
      >
        Install via npm, yarn or pnpm
      </Title>
      <Space h={40} />
      <Box
        sx={(theme) => ({
          backgroundColor: `${
            theme.colorScheme === "dark"
              ? theme.colors.dark[5]
              : theme.colors.gray[1]
          }`,
          border: `1px solid ${
            theme.colorScheme === "dark"
              ? theme.colors.dark[9]
              : theme.colors.gray[2]
          }`,
          padding: "1rem",
          borderRadius: "10px",
          width: "fit-content",
          fontSize: "1rem",
          fontWeight: 800,
        })}
      >
        npm i repilejs
      </Box>
      <Space h={20} />
      OR
      <Space h={20} />
      <Box
        sx={(theme) => ({
          backgroundColor: `${
            theme.colorScheme === "dark"
              ? theme.colors.dark[5]
              : theme.colors.gray[1]
          }`,
          border: `1px solid ${
            theme.colorScheme === "dark"
              ? theme.colors.dark[9]
              : theme.colors.gray[2]
          }`,
          padding: "1rem",
          borderRadius: "10px",
          width: "fit-content",
          fontSize: "1rem",
          fontWeight: 800,
        })}
      >
        yarn add repilejs
      </Box>
      <Space h={20} />
      OR
      <Space h={20} />
      <Box
        sx={(theme) => ({
          backgroundColor: `${
            theme.colorScheme === "dark"
              ? theme.colors.dark[5]
              : theme.colors.gray[1]
          }`,
          border: `1px solid ${
            theme.colorScheme === "dark"
              ? theme.colors.dark[9]
              : theme.colors.gray[2]
          }`,
          padding: "1rem",
          borderRadius: "10px",
          width: "fit-content",
          fontSize: "1rem",
          fontWeight: 800,
        })}
      >
        pnpm i repilejs
      </Box>
      <Space h={40} />
    </>
  );
};

const repileStateText = `const state = {
  firstName: "Varun",
  lastName: "Bardwaj"
};
export default state;
`;

const repileState = () => {
  return (
    <>
      <Title
        order={1}
        style={{
          fontWeight: "800",
        }}
        className="underline"
      >
        Repile State
      </Title>
      <Space h={40} />
      <Text
        sx={(theme) => ({
          color:
            theme.colorScheme === "dark"
              ? theme.colors.dark[1]
              : theme.colors.dark[4],
          "@media (min-width: 0px)": {
            fontSize: "0.7rem",
          },
          "@media (min-width: 400px)": {
            fontSize: "0.8rem",
          },
          "@media (min-width: 650px)": {
            fontSize: "1rem",
          },
          "@media (min-width: 1300px)": {
            fontSize: "1.1rem",
            paddingRight: "10rem",
          },
          textAlign: "left",
        })}
      >
        It is a group of reactive states that is managed by the <b>store</b> and
        returned by <b>getValue</b> method. It accepts data of type{" "}
        <b>&lt;Record&lt;string, any&gt;&gt;</b>
      </Text>
      <Space h={40} />
      <Title
        order={3}
        style={{
          fontWeight: "800",
        }}
      >
        Example
      </Title>
      <Space h={10} />
      <Text
        sx={(theme) => ({
          color:
            theme.colorScheme === "dark"
              ? theme.colors.dark[1]
              : theme.colors.dark[4],
          "@media (min-width: 0px)": {
            fontSize: "0.7rem",
          },
          "@media (min-width: 400px)": {
            fontSize: "0.8rem",
          },
          "@media (min-width: 650px)": {
            fontSize: "1rem",
          },
          "@media (min-width: 1300px)": {
            fontSize: "1.1rem",
            paddingRight: "10rem",
          },
          textAlign: "left",
        })}
      >
        Assuming you create a file named <b>state.ts</b>,
      </Text>
      <Space h={20} />
      <Box
        sx={(theme) => ({
          border: `1px solid ${
            theme.colorScheme === "dark"
              ? theme.colors.gray[8]
              : theme.colors.gray[2]
          }`,
          padding: "0rem 1rem",
          borderRadius: "10px",
          overflow: "auto",
        })}
      >
        <pre>{repileStateText}</pre>
      </Box>
      <Space h={20} />
    </>
  );
};

const createRepileStoreText = `import { createRepile } from "repilejs";
import state from "./state";

const { getValue, next } = createRepile({
  state: state,
  persist: true,
  identifier: "user"
});

export { getValue as user, next as updateUser };
`;

const useRepileStoreText = `import { user, updateUSer } from "./repile";

function Counter() {
  return (
    <div>{user().firstName}, {user().lastName}</div>
    <div onClick={() => updateUser("lastName", "P")}>
      Click here to update last name
    </div>
  )
}
`;

const createRepileStore = () => {
  return (
    <>
      <Title
        order={1}
        style={{
          fontWeight: "800",
        }}
        className="underline"
      >
        Create Store
      </Title>
      <Space h={40} />
      <Text
        sx={(theme) => ({
          color:
            theme.colorScheme === "dark"
              ? theme.colors.dark[1]
              : theme.colors.dark[4],
          "@media (min-width: 0px)": {
            fontSize: "0.7rem",
          },
          "@media (min-width: 400px)": {
            fontSize: "0.8rem",
          },
          "@media (min-width: 650px)": {
            fontSize: "1rem",
          },
          "@media (min-width: 1300px)": {
            fontSize: "1.1rem",
            paddingRight: "10rem",
          },
          textAlign: "left",
        })}
      >
        It is the main bucket which <b>stores</b> states. An app can have{" "}
        <b>multi stores</b> to represent data for a specific view and also
        heterogeneous in nature.
        <br />
        <br />
        The <b>createRepile</b> method is used to create a store. It accepts an
        object with keys <b>state</b>, <b>persist</b> and <b>identifier</b>{" "}
        where state is a mandatory field, persist is of type <b>boolean</b>,
        identifier is of type <b>string</b> and represents <b>store name</b>.
        <br />
        <br />
        It returns two methods,
        <br />
        <b>getValue()</b> to fetch data from store and{" "}
        <b>next("state_name", "state_value")</b> method to update a state in
        store.
      </Text>
      <Space h={40} />
      <Title
        order={3}
        style={{
          fontWeight: "800",
        }}
      >
        Example
      </Title>
      <Space h={20} />
      <Text
        sx={(theme) => ({
          color:
            theme.colorScheme === "dark"
              ? theme.colors.dark[1]
              : theme.colors.dark[4],
          "@media (min-width: 0px)": {
            fontSize: "0.7rem",
          },
          "@media (min-width: 400px)": {
            fontSize: "0.8rem",
          },
          "@media (min-width: 650px)": {
            fontSize: "1rem",
          },
          "@media (min-width: 1300px)": {
            fontSize: "1.1rem",
            paddingRight: "10rem",
          },
          textAlign: "left",
        })}
      >
        <b>Create the store</b>,
      </Text>
      <Space h={10} />
      <Box
        sx={(theme) => ({
          border: `1px solid ${
            theme.colorScheme === "dark"
              ? theme.colors.gray[8]
              : theme.colors.gray[2]
          }`,
          padding: "0rem 1rem",
          borderRadius: "10px",
          overflow: "auto",
        })}
      >
        <Space h={10} />
        <pre>{createRepileStoreText}</pre>
      </Box>
      <Space h={20} />
      <Text
        sx={(theme) => ({
          color:
            theme.colorScheme === "dark"
              ? theme.colors.dark[1]
              : theme.colors.dark[4],
          "@media (min-width: 0px)": {
            fontSize: "0.7rem",
          },
          "@media (min-width: 400px)": {
            fontSize: "0.8rem",
          },
          "@media (min-width: 650px)": {
            fontSize: "1rem",
          },
          "@media (min-width: 1300px)": {
            fontSize: "1.1rem",
            paddingRight: "10rem",
          },
          textAlign: "left",
        })}
      >
        <b>Use the store(in *.tsx)</b>,
      </Text>
      <Space h={10} />
      <Box
        sx={(theme) => ({
          border: `1px solid ${
            theme.colorScheme === "dark"
              ? theme.colors.gray[8]
              : theme.colors.gray[2]
          }`,
          padding: "0rem 1rem",
          borderRadius: "10px",
          overflow: "auto",
        })}
      >
        <Space h={10} />
        <pre>{useRepileStoreText}</pre>
      </Box>
      <Space h={20} />
    </>
  );
};

const examples = () => {
  return (
    <>
      <Title
        order={1}
        style={{
          fontWeight: "800",
        }}
        className="underline"
      >
        EXAMPLES
      </Title>
      <Space h={40} />
      <Title
        order={3}
        style={{
          fontWeight: "800",
        }}
      >
        Counter app with persist behavior
      </Title>
      <Space h={30} />
      <Box
        sx={(theme) => ({
          border: `1px solid ${
            theme.colorScheme === "dark"
              ? theme.colors.gray[8]
              : theme.colors.gray[2]
          }`,
          padding: "2rem",
          borderRadius: "10px",
          overflow: "auto",
        })}
      >
        <Tabs color="violet.5" defaultValue="app.tsx">
          <Tabs.List>
            <Tabs.Tab sx={{ fontSize: "1rem" }} value="state.ts">
              state.ts
            </Tabs.Tab>
            <Tabs.Tab sx={{ fontSize: "1rem" }} value="repile.ts">
              repile.ts
            </Tabs.Tab>
            <Tabs.Tab sx={{ fontSize: "1rem" }} value="app.tsx">
              Counter.tsx
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="state.ts" pt="xl">
            <pre>{stateTextPersist}</pre>
          </Tabs.Panel>
          <Tabs.Panel value="repile.ts" pt="xl">
            <pre>{replileTextPersist}</pre>
          </Tabs.Panel>
          <Tabs.Panel value="app.tsx" pt="xl">
            <pre>{appTextPersist}</pre>
          </Tabs.Panel>
        </Tabs>
        <Space h={50} />
        <Box
          sx={(theme) => ({
            border: `2px solid ${theme.colors.violet[theme.fn.primaryShade()]}`,
            display: "flex",
            width: "150px",
            height: "50px",
            borderRadius: "70px",
          })}
        >
          <Button
            sx={{ width: "50px", height: "45px", borderRadius: "70px" }}
            variant="subtle"
            color="violet"
            onClick={() => {
              updateApp("counter", app().counter - 1);
              updateApp("clicks", app().clicks + 1);
            }}
          >
            -
          </Button>
          <Center sx={{ width: "50px", height: "inherit" }}>
            {app().counter}
          </Center>
          <Button
            sx={{ width: "50px", height: "45px", borderRadius: "70px" }}
            variant="subtle"
            color="violet"
            onClick={() => {
              updateApp("counter", app().counter + 1);
              updateApp("clicks", app().clicks + 1);
            }}
          >
            +
          </Button>
        </Box>
        <Space h={30} />
        <Title order={6}>Number of clicks: {app().clicks}</Title>
      </Box>
      <Space h={50} />
      <Title
        order={3}
        style={{
          fontWeight: "800",
        }}
      >
        User input without persist behavior
      </Title>
      <Space h={20} />
      <Box
        sx={(theme) => ({
          border: `1px solid ${
            theme.colorScheme === "dark"
              ? theme.colors.gray[8]
              : theme.colors.gray[2]
          }`,
          padding: "2rem",
          borderRadius: "10px",
          overflow: "auto",
        })}
      >
        <Tabs color="violet.5" defaultValue="app.tsx">
          <Tabs.List>
            <Tabs.Tab sx={{ fontSize: "1rem" }} value="state.ts">
              state.ts
            </Tabs.Tab>
            <Tabs.Tab sx={{ fontSize: "1rem" }} value="repile.ts">
              repile.ts
            </Tabs.Tab>
            <Tabs.Tab sx={{ fontSize: "1rem" }} value="app.tsx">
              User.tsx
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="state.ts" pt="xl">
            <pre>{stateText}</pre>
          </Tabs.Panel>
          <Tabs.Panel value="repile.ts" pt="xl">
            <pre>{replileText}</pre>
          </Tabs.Panel>
          <Tabs.Panel value="app.tsx" pt="xl">
            <pre>{appText}</pre>
          </Tabs.Panel>
        </Tabs>
        <Space h={50} />
        <Input
          variant="filled"
          radius="xl"
          size="md"
          placeholder="Start typing..."
          onChange={(e: React.ChangeEvent) =>
            updateUser("name", (e.target as HTMLInputElement).value)
          }
          sx={(theme) => ({
            width: "250px",
            ".mantine-Input-input": {
              "&:focus": {
                borderColor: `${
                  theme.colors.violet[theme.fn.primaryShade()]
                } !important`,
              },
            },
          })}
        />
        <Space h={30} />
        <Text>
          Username:{"  "}
          <Text sx={{ fontWeight: 700 }} span>
            {user().name}
          </Text>
        </Text>
      </Box>
    </>
  );
};

const docList = [
  {
    title: "Get Started",
    id: "getStarted",
    icon: <Icon icon={<IconRocket size={20} />} />,
  },
  {
    title: "Repile State",
    id: "repileState",
    icon: <Icon icon={<IconStack2 size={20} />} />,
  },
  {
    title: "Create Store",
    id: "createRepileStore",
    icon: <Icon icon={<IconWorld size={20} />} />,
  },
  {
    title: "Examples",
    id: "examples",
    icon: <Icon icon={<IconComponents size={20} />} />,
  },
];
export default function Documentation() {
  const route = useNavigate();
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);
  return (
    <AppShell
      styles={{
        main: {
          background:
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        },
      }}
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      navbar={
        <Navbar
          hiddenBreakpoint="sm"
          hidden={!opened}
          width={{ sm: 200, lg: 250 }}
          sx={{ padding: "0.5rem" }}
        >
          <Box>
            {docList.map((doc, index) => (
              <NavLink
                key={index}
                sx={{ borderRadius: "4px", marginBottom: "0.5rem" }}
                label={
                  <Text
                    size="md"
                    color={
                      theme.colorScheme === "dark"
                        ? theme.colors.dark[1]
                        : theme.colors.dark[4]
                    }
                    sx={{
                      fontWeight: 700,
                    }}
                  >
                    {doc.title}
                  </Text>
                }
                icon={doc.icon}
                color="violet.5"
                active={app().documentation === doc.id}
                onClick={() => {
                  updateApp("documentation", doc.id);
                }}
              />
            ))}
          </Box>
        </Navbar>
      }
      header={
        <Header
          withBorder={false}
          height={60}
          sx={{
            backgroundColor: `${
              theme.colorScheme === "dark"
                ? theme.colors.dark[8]
                : theme.colors.gray[0]
            }`,
            borderBottom: `1px solid ${
              theme.colorScheme === "dark"
                ? theme.colors.dark[9]
                : theme.colors.gray[2]
            }`,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <MediaQuery largerThan="sm" styles={{ display: "none" }}>
              <Burger
                opened={opened}
                onClick={() => setOpened((o) => !o)}
                size="sm"
                color={theme.colors.gray[6]}
                ml="md"
              />
            </MediaQuery>
            <Box
              sx={(theme) => ({
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                height: "58px",
                padding: 20,
                backgroundColor: `${
                  theme.colorScheme === "dark"
                    ? theme.colors.dark[8]
                    : theme.colors.gray[0]
                }`,
              })}
            >
              <Box style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{ width: 30, cursor: "pointer" }}
                  onClick={() => route("/")}
                >
                  <Image src={LOGO} alt="Repile" />
                </div>
                <Space w={10} />
                <Text
                  sx={{ cursor: "pointer" }}
                  size="xl"
                  weight={900}
                  onClick={() => route("/")}
                >
                  Repile
                </Text>
                <Space w={10} />
                <Badge color="violet.5" size="sm" variant="outline">
                  <Text span sx={{ fontSize: "8px" }}>
                    v
                  </Text>
                  <Text span sx={{ fontSize: "12px" }}>
                    {packageJSON.version}
                  </Text>
                </Badge>
                <Space w={10} />
              </Box>
              <Box style={{ display: "flex" }}>
                <ActionIcon
                  variant="default"
                  radius="md"
                  size="lg"
                  onClick={() => {
                    window.open(
                      "https://github.com/varunpbardwaj/repile",
                      "_blank"
                    );
                  }}
                >
                  {<IconBrandGithub size={20} />}
                </ActionIcon>
                <Space w={10} />
                <ActionIcon
                  variant="default"
                  radius="md"
                  size="lg"
                  onClick={() => {
                    updateApp("dark", !app().dark);
                  }}
                >
                  {!app().dark ? (
                    <IconMoon size={20} />
                  ) : (
                    <IconSunHigh size={20} />
                  )}
                </ActionIcon>
              </Box>
            </Box>
          </div>
        </Header>
      }
    >
      <Box>
        <Box sx={{ padding: "1.5rem 2.5rem 2rem 2.5rem" }}>
          {app().documentation === "getStarted" && getStarted()}
          {app().documentation === "repileState" && repileState()}
          {app().documentation === "createRepileStore" && createRepileStore()}
          {app().documentation === "examples" && examples()}
        </Box>
        <Footer />
      </Box>
    </AppShell>
  );
}
