import { MantineProvider } from "@mantine/core";
import Router from "./Router";
import { app } from "./signals/app";

export default function Layout() {
  return (
    <MantineProvider
      theme={{
        fontFamily: `Inter, Avenir, Helvetica, Arial, sans-serif`,
        colorScheme: app().dark ? "dark" : "light",
        breakpoints: {
          xs: 500,
          sm: 800,
          md: 1000,
          lg: 1200,
          xl: 1400,
        },
        fontSizes: {
          xs: 10,
          sm: 12,
          md: 14,
          lg: 16,
          xl: 20,
        },
      }}
      withGlobalStyles
      withNormalizeCSS
    >
      <Router />
    </MantineProvider>
  );
}
