import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from "./Homepage";
import Documentation from "./Documentation";

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/documentation" element={<Documentation />} />
      </Routes>
    </BrowserRouter>
  );
}
