import LOGO from "../assets/images/repile.png";
import { Badge, Box, Image, Space, Text, ActionIcon } from "@mantine/core";
import { IconMoon, IconSunHigh, IconBrandGithub } from "@tabler/icons";
import { app, updateApp } from "../signals/app";
import packageJSON from "../../package.json";
import { useNavigate } from "react-router-dom";

function Header() {
  const route = useNavigate();

  return (
    <Box
      sx={(theme) => ({
        position: "fixed",
        zIndex: 9999,
        top: 0,
        left: 0,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100vw",
        height: "60px",
        backgroundColor: `${
          theme.colorScheme === "dark"
            ? theme.colors.dark[8]
            : theme.colors.gray[0]
        }`,
        borderBottom: `1px solid ${
          theme.colorScheme === "dark"
            ? theme.colors.dark[9]
            : theme.colors.gray[2]
        }`,
        padding: "0rem 1.2rem",
      })}
    >
      <Box style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{ width: 30, cursor: "pointer" }}
          onClick={() => route("/")}
        >
          <Image src={LOGO} alt="Repile" />
        </div>
        <Space w={10} />
        <Text
          sx={{ cursor: "pointer" }}
          size="xl"
          weight={900}
          onClick={() => route("/")}
        >
          Repile
        </Text>
        <Space w={10} />
        <Badge color="violet.5" size="sm" variant="outline">
          <Text span sx={{ fontSize: "8px" }}>
            v
          </Text>
          <Text span sx={{ fontSize: "12px" }}>
            {packageJSON.version}
          </Text>
        </Badge>
      </Box>
      <Box style={{ display: "flex" }}>
        <ActionIcon
          variant="default"
          radius="md"
          size="lg"
          onClick={() => {
            window.open("https://github.com/varunpbardwaj/repile", "_blank");
          }}
        >
          {<IconBrandGithub size={20} />}
        </ActionIcon>
        <Space w={10} />
        <ActionIcon
          variant="default"
          radius="md"
          size="lg"
          onClick={() => {
            updateApp("dark", !app().dark);
          }}
        >
          {!app().dark ? <IconMoon size={20} /> : <IconSunHigh size={20} />}
        </ActionIcon>
      </Box>
    </Box>
  );
}

export default Header;
