import { Box, Text } from "@mantine/core";

function Footer({ isDoc }: { isDoc?: boolean }) {
  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: isDoc ? "68px" : "120px",
        padding: 20,
        backgroundColor: `${
          theme.colorScheme === "dark"
            ? theme.colors.dark[8]
            : theme.colors.gray[0]
        }`,
      })}
    >
      <Text align="center">
        Designed and Developed by{" "}
        <Text
          component="a"
          href="https://github.com/varunpbardwaj"
          color="#7950F1"
          span
        >
          Varun Bardwaj
        </Text>
        <Text>Released under MIT Licence</Text>
      </Text>
    </Box>
  );
}

export default Footer;
