import { createRepile } from "repilejs";

const { getValue, next } = createRepile({
  state: {
    counter: 0,
    clicks: 0,
    dark: false,
    documentation: "getStarted",
  },
  persist: true,
  identifier: "app",
});

export { getValue as app, next as updateApp };
